<template>
  <div class="detail-wrap" id="service-detail">
    <!-- Loading ......... -->
    <div class="loader" id="loader">
      <div class="loader-inner">
        <img src="./imgs/loader.svg" alt="Loader" class="loader-img" />
      </div>
    </div>
    <!-- scroll top -->
    <a class="scroll-top link-text" href="#service-detail">
      <fa-icon icon="fas fa-angles-right" beat></fa-icon>
    </a>
    <Navigation>
      <template v-slot:slide>
        <div class="slide-wrap-menu">
          <div class="slide-inner">
            <div class="slide-item-sub-page active">
              <img :src="serviceInformation.image" alt="magarims luxury spa" />
              <div class="slide-background-shadow"></div>
              <div class="slide-text">
                <h2>{{ convertToTitleCase(serviceInformation.title) }}</h2>
                <p>
                  {{ serviceInformation.short_desc }}
                </p>
                <h3>
                  <img
                    class="logo-slide"
                    src="@/components/imgs/logo-footer.png"
                    alt
                  />Natural Beauty
                </h3>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Navigation>
    <div class="detail-container">
      <b-tabs align="center" active>
        <b-tab title="Details">
          <b-card>
            <b-card-text>
              <div class="editor-content view-detail">
                <PreviewEditor :contentPreview="serviceInformation.long_desc" />
              </div>
            </b-card-text>
            <div class="price-service">
              <!-- <b-table
                hover
                :items="serviceInformation.service_price"
                :fields="fields"
              >
                <template #cell(price)="data">
                  <span>{{ convertToCurrency(data.item.price) }}</span>
                </template>
              </b-table> -->
              <div class="total-packages-price">
                <h3
                  v-if="serviceInformation && serviceInformation.service_price"
                >
                  {{ $t("detail-service.price") }}: VND
                  {{
                    convertToCurrency(
                      serviceInformation.service_price[0].price
                    )
                  }}++/1 {{ $t("detail-service.package") }}
                </h3>
                <!-- <img
                  class="logo-slide"
                  src="@/components/imgs/logo-footer.png"
                  alt
                /> -->
              </div>
            </div>
          </b-card>
        </b-tab>
        <b-tab title="Booking">
          <b-card>
            <b-card-text>
              <p>{{ $t("title.booking-lable") }}</p>
            </b-card-text>
            <div>
              <b-form v-if="show" @submit.stop.prevent="handleSubmit">
                <div class="booking-form">
                  <div class="left-form">
                    <h3>{{ $t("title.your-information") }}</h3>
                    <b-form-group
                      id="input-group-fist-name"
                      :label="$t('title.full-name-lable')"
                      label-for="full-name"
                    >
                      <b-form-input
                        id="full-name"
                        name="full-name"
                        v-model="form.fullName"
                        type="text"
                        :placeholder="$t('place-holder.your-full-name')"
                        :state="validateState('full-name')"
                        aria-describedby="full-name-live-feedback"
                        v-validate="{ required: true }"
                        data-vv-as="full name"
                      ></b-form-input>
                      <b-form-invalid-feedback id="full-name-live-feedback">{{
                        $t("validate.full-name")
                      }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                      id="input-group-email"
                      :label="$t('title.email-lable')"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        type="email"
                        :placeholder="$t('place-holder.your-email')"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      id="input-group-number-phone"
                      :label="$t('title.number-phone-lable')"
                      label-for="number-phone"
                    >
                      <b-form-input
                        id="number-phone"
                        name="number-phone"
                        v-model="form.numberPhone"
                        type="number"
                        :placeholder="$t('place-holder.your-phone')"
                        :state="validateState('number-phone')"
                        aria-describedby="number-phone-live-feedback"
                        v-validate="{ required: true }"
                        data-vv-as="number phone"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        id="number-phone-live-feedback"
                        >{{
                          $t("validate.number-phone")
                        }}</b-form-invalid-feedback
                      >
                    </b-form-group>

                    <b-form-group
                      id="input-group-address"
                      :label="$t('title.address-lable')"
                      label-for="address"
                    >
                      <b-form-input
                        id="address"
                        v-model="form.address"
                        type="text"
                        :placeholder="$t('place-holder.your-address')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="right-form">
                    <h3>{{ $t("title.request-book") }}</h3>

                    <b-form-group
                      id="input-group-3"
                      :label="$t('title.package-lable')"
                      label-for="title"
                    >
                      <b-form-select
                        id="title"
                        v-model="form.package"
                        :options="packages_option"
                      ></b-form-select>
                    </b-form-group>

                    <b-form-group
                      id="input-group-arrival-date"
                      :label="$t('title.arrival-date-lable')"
                      label-for="arrival-date"
                    >
                      <b-form-datepicker
                        id="arrival-date"
                        v-model="form.arrivalDate"
                        class="mb-2"
                      ></b-form-datepicker>
                    </b-form-group>

                    <b-form-group
                      id="input-group-other-request"
                      :label="$t('title.message-lable')"
                      label-for="other-request"
                    >
                      <b-form-textarea
                        id="other-request"
                        v-model="form.otherRequest"
                        :placeholder="$t('place-holder.your-message')"
                        lazy-formatter
                      ></b-form-textarea>
                    </b-form-group>
                    <div class="btn-submmit-form">
                      <b-btn type="submit" :disabled="ordering">
                        <b-spinner small v-if="ordering"></b-spinner
                        >{{ $t("title.order-service") }}</b-btn
                      >
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </b-card>
        </b-tab>
      </b-tabs>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navigation from "@/components/common/Navigation.vue";
import Footer from "@/components/Footer.vue";
import $ from "jquery";
import { PAGE_API, USER_CONTACT_API } from "@/constants/api-path";
import axios from "axios";
import PreviewEditor from "@/components/common/PreviewEditor.vue";
export default {
  name: "ViewDetailService",
  data() {
    return {
      form: {
        package: "01 buổi",
        email: "",
        numberPhone: "",
        address: "",
        arrivalDate: "",
        otherRequest: "",
        fullName: "",
      },
      packages_option: [
        { text: "01 buổi", value: "01 buổi" },
        { text: "10 buổi", value: "10 buổi" },
        { text: "30 buổi", value: "30 buổi" },
      ],
      show: true,
      serviceInformation: {},
      fields: [
        { key: "title", label: "Number days of Spa" },
        { key: "price", label: "Price" },
      ],
      ordering: false,
    };
  },
  components: {
    Navigation,
    Footer,
    PreviewEditor,
  },
  mounted() {
    this.initLoading();
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    convertToTitleCase(str) {
      if (str === null || str === undefined || str === "") {
        return "";
      }
      return str.toUpperCase();
    },
    handlePageChange() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.requestOrderAndContact();
      });
    },
    resetFormData() {
      this.form.fullName = "";
      this.form.email = "";
      this.form.numberPhone = "";
      this.form.address = "";
      this.form.arrivalDate = "";
      this.form.otherRequest = "";
      this.form.package = "01 buổi";
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    requestOrderAndContact() {
      this.ordering = true;
      const requestBody = {
        email: this.form.email,
        name: this.form.fullName,
        phone: this.form.numberPhone,
        service_type: this.serviceInformation.title,
        message: `Arrival date : ${this.form.arrivalDate} - Package: ${this.form.package} - Address:${this.form.address} - Note: ${this.form.otherRequest}`,
      };
      axios
        .put(this.$config.backendApi + USER_CONTACT_API, requestBody)
        .then((res) => {
          if (res && res.data) {
            this.makeNotification(
              "success",
              this.$t("title.success"),
              this.$t("message.contact-success")
            );
            this.resetFormData();
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        })
        .finally(() => {
          this.ordering = false;
        });
    },
    makeNotification(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    convertToCurrency(number) {
      let formattedNumber = number
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      // formattedNumber += " VND";
      return formattedNumber;
    },
    getDetailService(id) {
      axios
        .get(this.$config.backendApi + PAGE_API, {
          params: {
            id,
          },
        })
        .then((res) => {
          if (res && res.data) {
            this.serviceInformation = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    initLoading() {
      // $("body").removeClass(".preload");
      $("#loader").fadeIn("fast");
      $("#loader").delay(1000).fadeOut("fast");
    },
    initAnimationHeaderSlide() {
      var addAnimatedForSlideText = function () {
        let activeSlide = $(".slide-item-sub-page.active .slide-text");
        activeSlide.find("h2").addClass("animated fadeInDown");
        activeSlide.find("p").addClass("animated fadeInUp");
        activeSlide.find("h3").addClass("animated fadeInLeft");
      };
      var removeAnimatedForSlideText = function () {
        let activeSlide = $(".slide-item-sub-page.active .slide-text");
        activeSlide.find("h2").removeClass("animated fadeInDown");
        activeSlide.find("p").removeClass("animated fadeInUp");
        activeSlide.find("h3").removeClass("animated fadeInLeft");
      };
      removeAnimatedForSlideText();
      setTimeout(() => {
        addAnimatedForSlideText();
      }, 100);
    },
  },
  watch: {
    "$route.params.service_id": {
      handler(value) {
        if (value) {
          this.initLoading();
          this.handlePageChange();
          this.initAnimationHeaderSlide();
          if (value) {
            this.getDetailService(value);
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
<style>
.detail-container {
  width: 800px;
  margin: 0 auto;
  /* margin-top: 20px !important; */
}
.price-service {
  border: 2px solid #777373;
  min-height: 200px;
  height: 90%;
  margin: 0 auto;
  margin-top: 50px;
}
.detail-container .card {
  box-shadow: 1px 0px 9px -3px black;
  border-top: unset !important;
  border-radius: unset !important;
  margin-bottom: 20px;
  min-height: 800px;
}
.detail-container .card-body {
  padding-top: 3rem;
  padding-left: 2.5rem;
}
.price-service h4 {
  margin: 10px;
  text-align: center;
}
.detail-container ul {
  /* border: none; */
}

.detail-container ul li {
  background-color: #72543a;
}
.detail-container ul li a {
  color: white;
  border-radius: unset !important;
  box-shadow: 1px 0px 9px -3px black;
}
.detail-container {
  margin-top: 20px;
  color: #72543a;
}
.right-form,
.left-form {
  width: 45%;
}
.booking-form {
  display: flex;
  justify-content: space-around;
}
.btn-submmit-form button {
  background-color: #72543a;
  color: white;
}
.detail-wrap {
  /* background: url("@/components/imgs/slide_2.jpg"); */
  background-size: cover;
}
@media (max-width: 1200px) {
  .detail-container {
    padding-top: 80px;
  }
}
@media (max-width: 800px) {
  .detail-container {
    width: 95%;
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .right-form,
  .left-form {
    width: 90%;
  }
  .booking-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.logo-slide {
  width: 150px !important;
  height: 150px !important;
}
.total-packages-price {
  text-align: center;
  margin-top: 20px;
  font-family: sans-serif !important;
}
.view-detail {
  font-family: "sans-serif" !important;
}
.slide-background-shadow {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0px;
  opacity: 0.2;
}
</style>
